<template>
  <div class="w-100">
    <!--begin::Content header-->
    <div class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
      Don't have an account yet? <router-link to="/sign-up" class="text-dark-60 text-primary text-hover-primary my-3 mr-2">Sign up.</router-link>
    </div>
    <!--end::Content header-->

    <!--begin::Signin-->
    <div class="row">
      <div class="col-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 col-xxl-4 offset-xxl-4 login-form login-signin">
        <div class="text-center mb-10 mb-lg-20">
          <h3 class="font-size-h1">Sign in</h3>
          <p class="text-muted font-weight-semi-bold">
            Enter your email and password
          </p>
        </div>

        <!-- begin::Error alert -->
        <div
          v-if="isErrorActive"
          class="mt-n10 mb-10"
        >
          <error-translation-alert
            ref="errorTranslationAlert"
            variant="light-danger"

            :error="activeError"

            class="mt-n10 mb-10"
          />
        </div>
        <!-- end::Error alert -->

        <!--begin::Form-->
        <b-form class="form" @submit.stop.prevent="onSubmit">
          <b-form-group label-for="email">
            <b-form-input
              ref="email"
              name="email"
              placeholder="Email"
              :class="['form-control form-control-solid h-auto py-5 px-6', {'is-invalid': $v.form.email.$error}]"
              :disabled="isLoadingForm"
              v-model="$v.form.email.$model"
            />

            <b-form-invalid-feedback>
              Email is required.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label-for="password">
            <b-form-input
              type="password"
              name="password"
              placeholder="Password"
              :class="['form-control form-control-solid h-auto py-5 px-6', {'is-invalid': $v.form.password.$error}]"
              :disabled="isLoadingForm"
              v-model="$v.form.password.$model"
            />

            <b-form-invalid-feedback>
              Password is required.
            </b-form-invalid-feedback>
          </b-form-group>

          <!--begin::Action-->
          <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
            <router-link to="/forgot-password" class="text-dark-60 text-primary text-hover-primary my-3 mr-2">
              Forgot password?
            </router-link>
            <button
              ref="kt_login_signin_submit"
              class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
              :disabled="isLoadingForm"
            >
              Sign in
            </button>
          </div>
          <!--end::Action-->
        </b-form>
        <!--end::Form-->
      </div>
      <!--end::Signin-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import {
  validations,
  errorHandling,
  errorComponentMixin,
  router as routerLib,
} from '@vedicium/core-vue';
import errorTranslationAlert from '@/components/errors/translation.alert.vue';

export default {
  mixins: [validationMixin, errorComponentMixin],
  name: 'login',
  components: {
    errorTranslationAlert,
  },
  data () {
    return {
      isLoadingForm: false,
      form: {
        email: null,
        password: null,
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
      },
      password: {
        required,
      },
    },
  },

  async mounted () {
    await this.$nextTick();

    if (this.$route.query.email) {
      this.$set(this.form, 'email', this.$route.query.email);
    }

    // Remove redirect path if not exists
    if (this.$route.query.redirect_uri) {
      const resolvedRedirectUri = this.$router.resolve({ path: this.$route.query.redirect_uri });
      if (!resolvedRedirectUri || !resolvedRedirectUri.route || resolvedRedirectUri.route.meta.error !== undefined) {
        routerLib.removeQueryFromRoute(this.$router, 'redirect_uri');
      }
    }

    this.$refs.email.focus();
  },

  methods: {
    async validate () {
      const validationArray = [this.$v];

      // Run the validations
      await validations.validateArray(validationArray);
    },
    async resetForm () {
      this.form = {
        email: null,
        password: null,
      };

      await this.$nextTick();

      this.$v.$reset();
    },
    async onSubmit () {
      errorHandling.clear({ component: this });
      this.$set(this, 'isLoadingForm', true);
      this.$refs.kt_login_signin_submit.classList.add('spinner', 'spinner-light', 'spinner-right');

      try {
        await this.validate();
        await this.$auth.signin(this.form.email, this.form.password);
      } catch (e) {
        errorHandling.handle(e, { component: this });
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
        this.$refs.kt_login_signin_submit.classList.remove('spinner', 'spinner-light', 'spinner-right');
      }

      this.$router.push({
        path: (this.$route.query.redirect_uri || '/dashboard'),
      });
    },
  },
};
</script>
